module.exports = {
  
    Bucket: 'https://pub-62dbb63c1c98404589daa842501d58f4.r2.dev',
    UseMultipleAddData: 'false',
    UseBigFile: 'true',
    UseFileNamesC: 'false',
    BigFile: '26.json',
    FileNames: 'dataset_c0.json,dataset_c11.json,dataset_c12.json,dataset_c8.json,dataset_c4.json,dataset_c6.json,dataset_c5.json,dataset_c16.json,dataset_c10.json,dataset_c9.json,dataset_c1.json,dataset_c15.json,dataset_c20.json,dataset_c3.json,dataset_c13.json,dataset_c2.json,dataset_c18.json,dataset_c19.json,dataset_c7.json,dataset_c14.json,dataset_c17.json',
    FileNamesC: 'dataset_0.json,dataset_11.json,dataset_12.json,dataset_8.json,dataset_4.json,dataset_6.json,dataset_5.json,dataset_16.json,dataset_10.json,dataset_9.json,dataset_1.json,dataset_15.json,dataset_20.json,dataset_3.json,dataset_13.json,dataset_2.json,dataset_18.json,dataset_19.json,dataset_7.json,dataset_14.json,dataset_17.json',
    LocalFile: '24.json', 
    Key: 'value', 
    EndPoint: 'https://vizde.bcaconsulting.ca/handleVizDataRequest'
  };