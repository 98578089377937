import {addDataToMap,addNotification,removeNotification} from "@kepler.gl/actions";
import axios from "axios";
import { loadRemoteMap } from "./actions";
import { BigFile, Bucket, UseBigFile, UseFileNamesC, UseMultipleAddData, EndPoint } from "../ascvariables";

// console.log(BigFile);
// console.log("hello world");

export const service = {
    datasets: [],
    config: {},
    // loadFileMultipleAddData: async (fileName, dispatch) => {
    //     const url = `${Bucket}/${fileName}`;
    //     dispatch(
    //     addNotification({
    //         id: fileName,
    //         message: `Loading... ${fileName}`
    //     })
    //     );
    //     await axios
    //     .get(url)
    //     .then((response) => {
    //         const data = response.data;
    //         for (let i = 0; i < data.datasets.length; i++) {
    //         dispatch(
    //             addDataToMap({
    //             datasets: {
    //                 info: {
    //                 label: data.datasets[i].data.label,
    //                 id: data.datasets[i].data.id
    //                 },
    //                 data: {
    //                 fields: data.datasets[i].data.fields,
    //                 rows: data.datasets[i].data.allData
    //                 }
    //             },
    //             options: { centerMap: true, keepExistingConfig: true },
    //             config: data.config
    //             })
    //         );
    //         }
    //         dispatch(removeNotification(fileName));
    //     })
    //     .catch((err) => {
    //         console.log(err);
    //         dispatch(removeNotification(fileName));
    //         dispatch(
    //         addNotification({
    //             id: `${fileName}_err`,
    //             message: `Error loading ${fileName}`
    //         })
    //         );
    //     });
    // },
    // loadFileMultipleAddData_filter: async (fileName, dispatch) => {
    //     const url = `${Bucket}/${fileName}`;
    //     dispatch(
    //     addNotification({
    //         id: fileName,
    //         message: `Loading... ${fileName}`
    //     })
    //     );
    //     await axios
    //     .get(url)
    //     .then((response) => {
    //         const data = response.data;
    //         for (let i = 0; i < data.datasets.length; i++) {
    //         const filtersConfig = data.config.config.visState.filters.filter(
    //             (filter) => filter.dataId.includes(data.datasets[i].data.id)
    //         );
    //         const layersConfig = data.config.config.visState.layers.filter(
    //             (layer) => layer.config.dataId === data.datasets[i].data.id
    //         );
    //         const interConfig = {
    //             tooltip: {
    //             fieldsToShow: Object.keys(
    //                 data.config.config.visState.interactionConfig.tooltip
    //                 .fieldsToShow
    //             )
    //                 .filter((key) => key.includes(data.datasets[i].data.id))
    //                 .reduce((cur, key) => {
    //                 return Object.assign(cur, {
    //                     [key]:
    //                     data.config.config.visState.interactionConfig.tooltip
    //                         .fieldsToShow[key]
    //                 });
    //                 }, {}),
    //             compareMode:
    //                 data.config.config.visState.interactionConfig.tooltip
    //                 .compareMode,
    //             compareType:
    //                 data.config.config.visState.interactionConfig.tooltip
    //                 .compareType,
    //             enabled:
    //                 data.config.config.visState.interactionConfig.tooltip.enabled
    //             },
    //             brush: data.config.config.visState.interactionConfig.brush,
    //             geocoder: data.config.config.visState.interactionConfig.geocoder,
    //             coordinate: data.config.config.visState.interactionConfig.coordinate
    //         };
    //         const mapConfig = {
    //             visState: {
    //             filters: filtersConfig,
    //             layers: layersConfig,
    //             interactionConfig: interConfig,
    //             splitMaps: data.config.config.visState.splitMaps,
    //             animationConfig: data.config.config.visState.animationConfig,
    //             layerBlending: data.config.config.visState.layerBlending
    //             },
    //             mapState: data.config.config.mapState,
    //             mapStyle: data.config.config.mapStyle
    //         };
    //         // delete mapConfig.mapStyle.mapStyles;
    //         dispatch(
    //             addDataToMap({
    //             datasets: {
    //                 info: {
    //                 label: data.datasets[i].data.label,
    //                 id: data.datasets[i].data.id
    //                 },
    //                 data: {
    //                 fields: data.datasets[i].data.fields,
    //                 rows: data.datasets[i].data.allData
    //                 }
    //             },
    //             options: { centerMap: true, keepExistingConfig: true },
    //             config: mapConfig
    //             })
    //         );
    //         }

    //         dispatch(removeNotification(fileName));
    //     })
    //     .catch((err) => {
    //         console.log(err);
    //         dispatch(removeNotification(fileName));
    //         dispatch(
    //         addNotification({
    //             id: `${fileName}_err`,
    //             message: `Error loading ${fileName}`
    //         })
    //         );
    //     });
    // },
    loadFileSingleAddData: async (dispatch, filename) => {
        // const url = EndPoint + '?path=' + `${filename}` + '.json';
        const url = EndPoint + '?path=' + `${filename}`;
        // console.log(`URL: ${url}`);
        dispatch(
        addNotification({
            id: filename,
            message: `Loading... ${filename}`
        })
        );
        console.log("starting await fetch");
        await fetch(url)
        .then(response => response.text())  // get the response text
        .then(text => {
        // console.log(text);  // log the response text
        return JSON.parse(text);  // parse the text as JSON
        })
        // .then(response => response.json())


        // .then(response => {
        //     console.log('Track Point 10');
        //     if (!response.ok) {
        //         throw new Error(`HTTP error! status: ${response.status}`);
        //     }
        //     console.log('Track Point 11');
        //     return response.json();
        //     console.log('Track Point 12');
        // })

        .then((data) => {
            console.log('Track Point 20');
            service.config = data.config;
            console.log('Track Point 30');
            for (let i = 0; i < data.datasets.length; i++) {
            service.datasets.push({
                info: {
                label: data.datasets[i].data.label,
                id: data.datasets[i].data.id
                },
                data: {
                fields: data.datasets[i].data.fields,
                rows: data.datasets[i].data.allData
                }
            });
            }
    
            dispatch(removeNotification(filename));
        })
        .catch((err) => {
            console.log(err);
            dispatch(removeNotification(filename));
            dispatch(
            addNotification({
                id: `${fileName}_err`,
                message: `Error loading ${filename}`
            })
            );
        });
    },
    // useBigFile: async (dispatch) => {
    //     const fileName = BigFile;
    //     // const fileName = process.env.BIG_FILE;
    //     await service.loadFileMultipleAddData_filter(fileName, dispatch);
    // },
    // notUseBigFile: async (dispatch) => {
    //     if (JSON.parse(UseFileNamesCC)) {
    //     for (let j = 0; j < service.fileNamesC.length; j++) {
    //         const fileName = service.fileNamesC[j];
    //         await service.loadFileMultipleAddData(fileName, dispatch);
    //     }
    //     } else {
    //     for (let j = 0; j < service.fileNames.length; j++) {
    //         const fileName = service.fileNames[j];
    //         await service.loadFileMultipleAddData_filter(fileName, dispatch);
    //     }
    //     }
    // },

    singleFileAddData: async (dispatch, filename) => {
        // if (JSON.parse(UseBigFile)) {
        // const fileName = BigFile;
        if (filename) {
        // const fileName = process.env.BIG_FILE;
        await service.loadFileSingleAddData(dispatch, filename);
        // delete service.config.mapStyle.mapStyles;
        dispatch(
            addDataToMap({
            datasets: service.datasets,
            // options: { centerMap: true, keepExistingConfig: true },
            options: { centerMap: true },
            config: service.config
            })
        );
        } else {
        //It does not render if all the files are not in FILE_NAMES
        for (let j = 0; j < service.fileNames.length; j++) {
            const fileName = service.fileNames[j];
            await service.loadFileSingleAddData(fileName, dispatch);
        }
        // delete service.config.mapStyle.mapStyles;
        dispatch(
            addDataToMap({
            datasets: service.datasets,
            options: { centerMap: true, keepExistingConfig: true },
            config: service.config
            })
        );
        }
    },

    loadFiles: async (dispatch, filename) => {
        service.singleFileAddData(dispatch, filename);

    },
    // loadRemoteMap: (dispatch, query) => {
    //     const fileName = VARIABLES.BIG_FILE;
    //     query.mapUrl = `/api/${fileName}`;
    //     if (query.mapUrl) {
    //     dispatch(
    //         addNotification({
    //         id: fileName,
    //         message: `Loading... ${fileName}`
    //         })
    //     );
    //     dispatch(loadRemoteMap({ dataUrl: query.mapUrl }));
    //     dispatch(removeNotification(fileName));
    //     }
    // }
};
